<template>
  <div id="renderResource">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">进件管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 搜索区域视图 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input clearable v-model="queryFrom.name" placeholder="申请人姓名" class="input-with-select"> </el-input>
        </el-col>
        <el-col :span="5">
          <el-input v-model="queryFrom.idcard" clearable placeholder="身份证号码" class="input-with-select"> </el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="queryFrom.cityId" clearable placeholder="申请城市">
            <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="queryFrom.approvalState" clearable placeholder="审核状态">
            <el-option :key="item.value" v-for="item in optionsAudit" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input v-model="queryFrom.emergencyPhone" clearable placeholder="紧急联系人手机号" class="input-with-select"> </el-input>
        </el-col>
      </el-row>
      <div style="width: 100%; height: 20px"></div>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-date-picker v-model="queryFrom.yearMonth" type="month" value-format="yyyy-MM" placeholder="创建日期(月)"> </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button class="searchButton" type="primary" size="medium" @click="searchList()">查询</el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <div class="table_header">
        <el-button type="primary" round size="mini" icon="el-icon-plus" @click="openAddModal()">新增</el-button>
        <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcel()">导出</el-button>
      </div>
      <el-table
        :data="taleListdata"
        style="width: 100%; font-size: 14px"
        border
        :header-cell-style="{
          color: '#333',
          fontFamily: 'MicrosoftYaHeiUI',
          fontSize: '14px',
          fontWeight: 900,
          backgroundColor: '#eff3f8',
        }"
      >
        <el-table-column type="index" label="序" width="50" align="center">
          <template slot-scope="scope">
            <span>{{ (pagenum - 1) * size + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="申请人姓名" width="120"> </el-table-column>
        <el-table-column prop="idcard" label="身份证号码" width="200"> </el-table-column>
        <el-table-column prop="cityName" label="城市" align="center"> </el-table-column>
        <el-table-column prop="car" label="申请车辆" align="center" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="requestAmount" label="申请金额" align="center"> </el-table-column>
        <el-table-column prop="approvalAmount" label="审批金额" align="center"> </el-table-column>
        <el-table-column prop="rcmScore" label="风控模型分数" align="center" width="80"> </el-table-column>
        <el-table-column prop="approvalState" label="审批状态" align="center">
          <template slot-scope="scope" v-if="scope.row.approvalState != null">
            <el-tag :type="optionsAudit[scope.row.approvalState - 1].style">
              {{ optionsAudit[scope.row.approvalState - 1].label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" width="100"> </el-table-column>
        <el-table-column label="操作" width="300" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="success" icon="el-icon-tickets" @click="readResource(scope.row)">查看</el-button>
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editResource(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeRole(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagenum"
        :page-sizes="[1, 5, 10, 20]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 新增/编辑进件弹窗 -->
    <el-dialog width="70%" title="进件" :visible.sync="resourceVisible" @close="hiddlenFormDialog" class="resourceDialog">
      <el-form :model="sourceForm" ref="sourceFormRef" :rules="sourceFormRules">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="申请城市" prop="cityId">
              <el-select v-model="sourceForm.cityId" clearable placeholder="请选择">
                <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申请人姓名" prop="name">
              <el-input placeholder="请输入" autocomplete="off" v-model="sourceForm.name" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申请人身份证号码" prop="idcard">
              <el-input placeholder="请输入" v-model="sourceForm.idcard" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申请车辆" prop="car">
              <el-input placeholder="请输入" v-model="sourceForm.car" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="申请金额(元)" prop="requestAmount">
              <el-input placeholder="请输入" v-model="sourceForm.requestAmount" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审批金额(元)" prop="approvalAmount">
              <el-input autocomplete="off" placeholder="请输入" v-model="sourceForm.approvalAmount" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审批状态" prop="approvalState">
              <el-select v-model="sourceForm.approvalState" clearable placeholder="请选择">
                <el-option :key="item.value" v-for="item in optionsAudit" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6"> </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="风控模型分数" prop="rcmScore">
              <el-input autocomplete="off" placeholder="请输入" v-model="sourceForm.rcmScore" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户等级" prop="customerLevel">
              <el-select v-model="sourceForm.customerLevel" clearable placeholder="请选择">
                <el-option :key="item.value" v-for="item in optionsLevel" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="sourceForm.remark" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="contractTitle">
          <el-divider content-position="left"><i class="el-icon-mobile-phone"></i> 紧急联系人 </el-divider>
        </div>
        <el-row :gutter="20" v-for="(item, index) in sourceForm.emergencies" :key="item.id">
          <el-col :span="6">
            <el-form-item
              :label="`联系人${index + 1}_姓名:`"
              :prop="'emergencies[' + index + '].name'"
              :rules="[
                { message: '请填写联系人姓名', required: true, trigger: 'blur' },
                { min: 1, max: 20, message: '联系人姓名长度在1到20个字符', trigger: 'blur' },
              ]"
            >
              <el-input placeholder="请输入" v-model="item.name" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :label="`联系人${index + 1}_手机号:`"
              :prop="'emergencies[' + index + '].phone'"
              :rules="[
                { message: '手机号格式不正确', pattern: /^1[3-9]\d{9}$/, trigger: 'blur' },
                { message: '请填写手机号', required: true, trigger: 'blur' },
              ]"
            >
              <el-input v-model="item.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <span>&nbsp;&nbsp;</span>
              <div>
                <el-button size="mini" icon="el-icon-delete" type="danger" circle @click.prevent="removeContact(index)"></el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <div v-if="sourceForm.emergencies.length === 0">
              <el-empty description="暂未绑定紧急联系人"> </el-empty>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" style="margin-left: 10px" icon="el-icon-plus" plain type="primary" @click="addContact()">紧急联系人</el-button>
        <el-button size="medium" @click="resourceVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="submitResource">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 查看进件信息弹窗 -->
    <el-dialog :modal-append-to-body="false" :visible.sync="PreviewVisible" width="60%" @close="PerviewhiddlenDialog" class="resourcePreviewDialog">
      <el-descriptions title="进件信息" :column="4" direction="vertical" size="medium" :border="true" :contentStyle="CS">
        <el-descriptions-item label="申请人姓名">{{ PreviewData.name }}</el-descriptions-item>
        <el-descriptions-item label="申请人身份证号码">{{ PreviewData.idcard }}</el-descriptions-item>
        <el-descriptions-item label="申请城市">{{ PreviewData.cityName }}</el-descriptions-item>
        <el-descriptions-item label="审批状态" v-if="PreviewData.approvalState">
          <el-tag size="small" :type="optionsAudit[PreviewData.approvalState - 1].style">
            {{ optionsAudit[PreviewData.approvalState - 1].label }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="申请金额(元)">{{ PreviewData.requestAmount || "--" }}</el-descriptions-item>
        <el-descriptions-item label="审批金额(元)">{{ PreviewData.approvalAmount || "--" }}</el-descriptions-item>
        <el-descriptions-item label="申请车辆">{{ PreviewData.car || "--" }}</el-descriptions-item>
        <el-descriptions-item label="风控模型分数">{{ PreviewData.rcmScore || "--" }}</el-descriptions-item>
        <el-descriptions-item label="客户等级">{{ PreviewData.customerLevel || "--" }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{ PreviewData.remark || "--" }}</el-descriptions-item>
      </el-descriptions>
      <div style="width: 100%; height: 20px"></div>
      <div v-if="PreviewData.isDomains">
        <div style="font-size: 16px; font-weight: 600; margin-bottom: 20px">紧急联系人</div>
        <el-descriptions v-for="(item, index) in PreviewData.emergencies" :key="index" :colon="false" title="" :column="2" size="medium " :labelStyle="{ width: '110px' }">
          <el-descriptions-item :label="`联系人${index + 1}_姓名:`">{{ item.name }}</el-descriptions-item>
          <el-descriptions-item :label="`联系人${index + 1}_手机号:`">{{ item.phone }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { resourceList, addResource, updateResource, queryIdResource, deleResource, exportResource } from "@/api/resource.js";
import { cityList } from "@/api/index.js";
export default {
  name: "",
  data() {
    return {
      taleListdata: [], //列表数据
      queryFrom: {
        name: "",
        idcard: "",
        cityId: "",
        approvalState: "",
        emergencyPhone: "",
        yearMonth: "",
      },
      optionsCity: [],
      optionsAudit: [
        { label: "通过", value: 1, style: "success" },
        { label: "拒件", value: 2, style: "danger" },
        { label: "待定", value: 3, style: "warning" },
        { label: "放弃", value: 4, style: "info" },
      ],
      optionsLevel: [
        { label: "A", value: "A" },
        { label: "B", value: "B" },
        { label: "C", value: "C" },
        { label: "D", value: "D" },
      ],
      pagenum: 1,
      size: 10,
      total: 0,

      resourceId: -1, //进件资源id
      sourceForm: {
        cityId: "", // 申请城市
        name: "", // 申请人姓名
        idcard: "", // 申请人身份证号码
        car: "", // 申请车辆
        requestAmount: "", // 申请金额
        approvalAmount: "", // 审批金额
        approvalState: "", // 审批状态
        remark: "", // 备注
        emergencies: [],
        rcmScore: "", // 风控模型分数
        customerLevel: "", // 客户等级
      }, //角色信息表单
      resourceVisible: false,
      sourceFormRules: {
        cityId: [{ required: true, message: "请选择申请城市", trigger: ["blur", "change"] }],
        name: [
          { required: true, message: "请填写申请人姓名", trigger: "blur" },
          { min: 1, max: 20, message: "申请人姓名长度在1到20个字符", trigger: "blur" },
        ],
        idcard: [
          { required: true, message: "请填写申请人身份证号码", trigger: "blur" },
          {
            message: "身份证号格式错误(18位)",
            pattern: /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/,
            trigger: "blur",
          },
        ],
        car: [{ min: 0, max: 100, message: "申请车辆长度不超过100个字符", trigger: "blur" }],
        requestAmount: [
          {
            message: " 申请金额不能超过10位正整数",
            pattern: /^\d{1,10}$/,
            trigger: "blur",
          },
        ],
        approvalAmount: [
          {
            message: "审批金额不能超过10位正整数",
            pattern: /^\d{1,10}$/,
            trigger: "blur",
          },
        ],
        remark: [
          {
            min: 0,
            max: 500,
            message: "备注信息长度在0到500个字符",
            trigger: "blur",
          },
        ],
        rcmScore: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
                if (!pattern.test(value)) {
                  callback(new Error("分数范围为0.01-110之间数值"));
                } else if (value < 0 || value > 110) {
                  callback(new Error("分数范围为0.01-110之间数值"));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      PreviewVisible: false, //
      PreviewData: {},
      //  查看弹窗布局
      CS: {
        "text-align": "left", //文本居中
        "min-width": "200px", //最小宽度
        "word-break": "break-all", //过长时自动换行
      },
    };
  },
  computed: {
    //动态遍历需要传的查询参数
    queryFromData() {
      let date = {};
      for (const key in this.queryFrom) {
        if (this.queryFrom[key] != "") {
          date[key] = this.queryFrom[key];
        }
      }
      return date;
    },
  },
  created() {
    this.getResourceList();
    this.getcityList();
  },
  methods: {
    getcityList() {
      cityList().then((res) => {
        this.optionsCity = res.data.dataList;
      });
    },
    getResourceList() {
      resourceList(this.queryFromData, this.pagenum, this.size).then((res) => {
        console.log(res);
        const DataList = res.data.dataList;
        DataList.forEach((ele) => {
          ele.createDate = this.$moment(ele.createDate).format("YYYY-MM-DD HH:mm:ss");
        });
        this.taleListdata = DataList;
        this.total = res.data.total;
      });
    },
    //刷新数据
    refreshData() {
      this.size = 10;
      this.pagenum = 1;
      this.getResourceList();
    },
    searchList() {
      this.refreshData();
    },
    //监听pagesize改变的事件
    handleSizeChange(newSize) {
      this.size = newSize;
      this.getResourceList(); //发起数据请求
    },
    //监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.getResourceList(); //发起数据请求
    },
    //点击新增按钮
    openAddModal() {
      this.resourceVisible = true;
      this.resourceId = -1;
      this.sourceForm.emergencies = [
        {
          id: 0,
          name: "",
          phone: "",
        },
      ];
    },
    //点击编辑按钮
    editResource(row) {
      const { id } = row;
      const editForm = {};
      queryIdResource(id).then((res) => {
        console.log(res);
        const resourceInf = res.data;
        for (const key in this.sourceForm) {
          if (key == "emergencies") {
            editForm[key] = resourceInf[key] ?? [];
          } else {
            editForm[key] = resourceInf[key] ?? "";
          }
        }
        console.log("编辑信息", editForm);
        this.sourceForm = editForm;
        this.resourceVisible = true;
        this.resourceId = id;
      });
    },

    //确认提交新增/编辑
    submitResource() {
      this.$refs.sourceFormRef.validate((valid) => {
        console.log(valid);
        if (valid) {
          const newContacts = this.sourceForm.emergencies.map((obj) => {
            const { name, phone } = obj;
            return { name, phone };
          });
          console.log(newContacts);
          const sourceForm = { ...this.sourceForm, emergencies: newContacts, rcmScore: parseFloat(this.sourceForm.rcmScore) };
          //添加进件
          if (this.resourceId < 0) {
            addResource(sourceForm).then((res) => {
              this.resourceVisible = false;
              this.$message.success("新增成功");
              this.refreshData();
            });
          } else {
            //编辑进件
            updateResource(sourceForm, this.resourceId).then((res) => {
              this.resourceVisible = false;
              this.$message.success("编辑成功");
              this.refreshData();
            });
          }
        }
      });
    },

    //删除角色
    removeRole(id) {
      this.$confirm("此操作将永久删除该进件信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleResource(id).then((res) => {
            this.refreshData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //隐藏添加/编辑表单那弹窗
    hiddlenFormDialog() {
      console.log("执行");
      this.$refs.sourceFormRef.clearValidate(); ///重置新增表单
      Object.keys(this.sourceForm).forEach((key) => (this.sourceForm[key] = ""));
      this.resourceId = -1;
    },
    // 新增紧急联系人
    addContact() {
      this.sourceForm.emergencies.push({
        id: Date.now(),
        name: "",
        phone: "",
      });
    },
    // 移除紧急联系人
    removeContact(index) {
      this.sourceForm.emergencies.splice(index, 1);
    },
    readResource(row) {
      const { id } = row;
      let isDomains = false;
      queryIdResource(id).then((res) => {
        const resourceInf = res.data;
        if (resourceInf.emergencies && resourceInf.emergencies.length > 0) {
          isDomains = true;
        } else {
          isDomains = false;
        }
        this.PreviewData = { isDomains, ...resourceInf };
        this.PreviewVisible = true;
      });
    },
    // 隐藏查看弹窗
    PerviewhiddlenDialog() {
      this.PreviewVisible = false;
      this.PreviewData = {};
      this.resourceId = -1;
    },
    // 下载excel表格
    DownExcel() {
      var _this = this;
      exportResource(this.queryFromData)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = "进件管理_" + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
<style lang='less' scoped>
#renderResource {
  padding: 60px 20px 20px 20px;
}

.resourceDialog /deep/ .el-dialog__body {
  height: 55vh;
  overflow: auto;
}

.resourceDialog /deep/ .el-select {
  width: 100%;
}
.resourceDialog /deep/ .el-dialog__body {
  padding: 30px;
}
</style>